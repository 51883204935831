<template>
	<div class="p-grid p-fluid dashboard">
		<div class="p-col-12">
            <h3 style="color:#ffcc2a;">{{$t('Dashboard')}}</h3>
		</div>
        <div class="p-col-12 p-lg-4">
            <div class="card summary">
                <span class="title">{{$t('Cotizaciones')}}</span>
                <span class="detail">
                    <br>
                    {{$t('Pólizas Emitidas')}}: {{cotizaciones[1]}}
                    <br>
                    {{$t('Pendientes')}}: {{cotizaciones[2]}}
                </span>
                <span class="count visitors">{{cotizaciones[0]}}</span>
            </div>
        </div>
        <div class="p-col-12 p-lg-4">
            <div class="card summary">
                <span class="title">{{$t('Ventas')}}</span>
                <span class="detail">
                    <br>
                    {{$t('Pagos Recibidos')}}: {{moneda(ventas[1])}}
                    <br>
                    {{$t('Pagos por Cobrar')}}: {{moneda(ventas[2])}}          
                </span>
                <span class="count revenue">{{moneda(ventas[0])}}</span>
            </div>
        </div>
        <div class="p-col-12 p-lg-4">
            <div class="card summary">
                <span class="title">{{$t('Usuarios')}}</span>
                <span class="detail">
                    <br>
                    {{$t('En Póliza')}}: {{clientes[1]}}
                    <br>
                    {{$t('Fuera de Póliza')}}: {{(clientes[0]-clientes[1])}}               
                </span>
                <span class="count purchases">{{clientes[0]}}</span>
            </div>
        </div>
        <div class="p-col-12 p-lg-4">
            <div class="card summary">
                <span class="title">{{$t('Cobertura')}}</span>
                <span class="detail">
                    <br>
                    {{$t('Periodo consumido')}}: {{cobertura[1]}}
                    <br>
                    {{$t('Periodo por consumir')}}: {{cobertura[2]}}                  
                </span>
                <span class="count visitors">{{cobertura[0]}}</span>
            </div>
        </div>
        <div class="p-col-12 p-lg-4">
            <div class="card summary">
                <span class="title">{{$t('Quejas')}}</span>
                <span class="detail">
                    <br>
                    {{$t('Pendientes')}}: {{quejas[1]}}
                    <br>
                    {{$t('Resueltas')}}: {{quejas[2]}}
                </span>
                <span class="count revenue">{{quejas[0]}}</span>
            </div>
        </div>
        <div class="p-col-12 p-lg-4">
            <div class="card summary">
                <span class="title">{{$t('Reclamaciones')}}</span>
                <span class="detail">
                    <br>
                    {{$t('Finalizadas')}}: {{reclamaciones[2]}}                    
                    <br>
                    {{$t('En Proceso')}}: {{reclamaciones[1]}}
                </span>
                <span class="count purchases">{{reclamaciones[0]}}</span>
            </div>
        </div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Cotizaciones')}}</h5>
				<Chart type="line" :data="grafica1" v-if="grafica"/>
			</div>
		</div>
		
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Clientes')}}</h5>
				<Chart type="bar" :data="grafica2" v-if="grafica"/>
			</div>
		</div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Reclamaciones')}}</h5>
				<Chart type="bar" :data="grafica3" v-if="grafica"/>
			</div>
		</div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Reclamaciones')}}</h5>
				<Chart type="bar" :data="grafica4" v-if="grafica"/>
			</div>
		</div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Pólizas')}}</h5>
				<Chart type="doughnut" :data="grafica5" v-if="grafica"/>
			</div>
		</div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Pólizas')}}</h5>
				<Chart type="bar" :data="grafica6" v-if="grafica"/>
			</div>
		</div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Pagos')}}</h5>
				<Chart type="bar" :data="grafica7" v-if="grafica"/>
			</div>
		</div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Monto')}}</h5>
				<Chart type="bar" :data="grafica8" v-if="grafica"/>
			</div>
		</div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Quejas')}}</h5>
				<Chart type="bar" :data="grafica9" v-if="grafica"/>
			</div>
		</div>
		<div class="p-col-12 p-lg-6">
			<div class="card">
				<h5 class="centerText">{{$t('Monto de Reserva')}}</h5>
				<Chart type="bar" :data="grafica10" v-if="grafica"/>
			</div>
		</div>

    </div>
</template>

<script>
import API from "../service/API";
const Consulta = new API('Dashboard','Dashboard');
import { useI18nPlugin } from '@unify/vuex-i18n';

export default {
	components: {
    },
	data() {
		return {
            grafica: false,
            cotizaciones: [0,0,0],
            ventas: [0,0,0],
            clientes: [0,0],
            cobertura: [0,0,0],
            quejas: [0,0,0],
            reclamaciones: [0,0,0],

            grafica1: {
				labels: [],
				datasets: [
					{
						label: 'Fire',
						data: [],
						fill: false,
						backgroundColor: '#FF6384',
						borderColor: '#FF6384'
					},
					{
						label: 'Vehicle',
						data: [],
						fill: false,
						backgroundColor: '#36A2EB',
						borderColor: '#36A2EB'
					},
					{
						label: 'Health',
						data: [],
						fill: false,
						backgroundColor: '#FFCE56',
						borderColor: '#FFCE56'
					},
					{
						label: 'Project',
						data: [],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e'
					}
				]
			},

            grafica2: {
				labels: [],
				datasets: [
					{
						label: 'Clientes',
						data: [],
						fill: false,
						backgroundColor: '#FF6384',
						borderColor: '#FF6384'
					},
				]
			},

            grafica3: {
				labels: [],
				datasets: [
					{
						label: 'Fire',
						data: [],
						fill: false,
						backgroundColor: '#FF6384',
						borderColor: '#FF6384'
					},
					{
						label: 'Vehicle',
						data: [],
						fill: false,
						backgroundColor: '#36A2EB',
						borderColor: '#36A2EB'
					},
					{
						label: 'Health',
						data: [],
						fill: false,
						backgroundColor: '#FFCE56',
						borderColor: '#FFCE56'
					},
					{
						label: 'Project',
						data: [],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e'
					}
				]
			},

            grafica4: {
				labels: ['Claims','Pend. To Pay','Paid'],
				datasets: [
					{
						label: 'Fire',
						data: [],
						fill: false,
						backgroundColor: '#FF6384',
						borderColor: '#FF6384'
					},
					{
						label: 'Vehicle',
						data: [],
						fill: false,
						backgroundColor: '#36A2EB',
						borderColor: '#36A2EB'
					},
					{
						label: 'Health',
						data: [],
						fill: false,
						backgroundColor: '#FFCE56',
						borderColor: '#FFCE56'
					},
					{
						label: 'Project',
						data: [],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e'
					}
				]
			},

			grafica5: {
				labels: ['Fire', 'Vehicle', 'Health', 'Project'],
				datasets: [
					{
                        data: [1, 1, 1,1],
						backgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
                            '#00bb7e'
						],
						hoverBackgroundColor: [
							"#FF6384",
							"#36A2EB",
							"#FFCE56",
                            '#00bb7e'
						]
					}
				]
			},
            
            grafica6: {
				labels: ['Assets','Suspended','Overdue','Renewed','Canceled'],
				datasets: [
					{
						label: 'Fire',
						data: [],
						fill: false,
						backgroundColor: '#FF6384',
						borderColor: '#FF6384'
					},
					{
						label: 'Vehicle',
						data: [],
						fill: false,
						backgroundColor: '#36A2EB',
						borderColor: '#36A2EB'
					},
					{
						label: 'Health',
						data: [],
						fill: false,
						backgroundColor: '#FFCE56',
						borderColor: '#FFCE56'
					},
					{
						label: 'Project',
						data: [],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e'
					}
				]
			},

            grafica7: {
				labels: [],
				datasets: [
					{
						label: 'Paid out',
						data: [],
						fill: false,
						backgroundColor: '#FF6384',
						borderColor: '#FF6384'
					},
					{
						label: 'Pending',
						data: [],
						fill: false,
						backgroundColor: '#36A2EB',
						borderColor: '#36A2EB'
					},
				]
			},

            grafica8: {
				labels: [],
				datasets: [
					{
						label: 'Amount',
						data: [],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e'
					},
				]
			},

            grafica9: {
				labels: [],
				datasets: [
					{
						label: 'Solution',
						data: [],
						fill: false,
						backgroundColor: '#FF6384',
						borderColor: '#FF6384'
					},
					{
						label: 'Pending',
						data: [],
						fill: false,
						backgroundColor: '#36A2EB',
						borderColor: '#36A2EB'
					},
				]
			},

            grafica10: {
				labels: [],
				datasets: [
					{
						label: 'Fire',
						data: [],
						fill: false,
						backgroundColor: '#FF6384',
						borderColor: '#FF6384'
					},
					{
						label: 'Vehicle',
						data: [],
						fill: false,
						backgroundColor: '#36A2EB',
						borderColor: '#36A2EB'
					},
					{
						label: 'Health',
						data: [],
						fill: false,
						backgroundColor: '#FFCE56',
						borderColor: '#FFCE56'
					},
					{
						label: 'Project',
						data: [],
						fill: false,
						backgroundColor: '#00bb7e',
						borderColor: '#00bb7e'
					}
				]
			},



		}
	},
	productService: null,
	eventService: null,
	created() {
		this.i18n = useI18nPlugin();
		this.$store.commit('Validar',this.$route);
		if (this.$store.state.mobile){this.size = '90vw';}
        this.Mostrar();		
	},
	mounted() {
	},
	methods: {
        Mostrar(){
            this.grafica = false;
            Consulta.Ini().then(result => {
                this.cotizaciones = result.consult.cotizaciones;
                this.ventas = result.consult.ventas;
                this.clientes = result.consult.clientes;
                this.quejas = result.consult.quejas;
                this.reclamaciones = result.consult.reclamaciones;

                this.grafica1.labels = result.consult.grafica1.labels;
                this.grafica1.datasets[0].data = result.consult.grafica1.Fire;
                this.grafica1.datasets[1].data = result.consult.grafica1.Vehicle;
                this.grafica1.datasets[2].data = result.consult.grafica1.Health;
                this.grafica1.datasets[3].data = result.consult.grafica1.Project;

                this.grafica2.labels = result.consult.grafica2.labels;
                this.grafica2.datasets[0].data = result.consult.grafica2.Clientes;

                this.grafica3.labels = result.consult.grafica3.labels;
                this.grafica3.datasets[0].data = result.consult.grafica3.Fire;
                this.grafica3.datasets[1].data = result.consult.grafica3.Vehicle;
                this.grafica3.datasets[2].data = result.consult.grafica3.Health;
                this.grafica3.datasets[3].data = result.consult.grafica3.Project;

                this.grafica4.datasets[0].data = result.consult.grafica4.Fire;
                this.grafica4.datasets[1].data = result.consult.grafica4.Vehicle;
                this.grafica4.datasets[2].data = result.consult.grafica4.Health;
                this.grafica4.datasets[3].data = result.consult.grafica4.Project;

                this.grafica5.datasets[0].data = result.consult.grafica5;

                this.grafica6.datasets[0].data = result.consult.grafica4.Fire;
                this.grafica6.datasets[1].data = result.consult.grafica4.Vehicle;
                this.grafica6.datasets[2].data = result.consult.grafica4.Health;
                this.grafica6.datasets[3].data = result.consult.grafica4.Project;

                this.grafica7.labels = result.consult.grafica3.labels;
                this.grafica7.datasets[0].data = result.consult.grafica7.Pagado;
                this.grafica7.datasets[1].data = result.consult.grafica7.Pendiente;

                this.grafica8.labels = result.consult.grafica8.labels;
                this.grafica8.datasets[0].data = result.consult.grafica8.Monto;

                this.grafica9.labels = result.consult.grafica9.labels;
                this.grafica9.datasets[0].data = result.consult.grafica9.Solucion;
                this.grafica9.datasets[1].data = result.consult.grafica9.Pendiente;

                this.grafica10.labels = result.consult.grafica10.labels;
                this.grafica10.datasets[0].data = result.consult.grafica10.Fire;
                this.grafica10.datasets[1].data = result.consult.grafica10.Vehicle;
                this.grafica10.datasets[2].data = result.consult.grafica10.Health;
                this.grafica10.datasets[3].data = result.consult.grafica10.Project;

                this.grafica = true;
            }); 
        },

        moneda(number){
            return new Intl.NumberFormat("en", {style: "currency", currency:this.$store.state.currency}).format(parseFloat(number).toFixed(2));
		},


	},
	computed: {

    }

}
</script>
